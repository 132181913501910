import React from "react"
import Layout from "../components/Layout"

export default function SanPham() {
  return (
    <Layout>
      <div className="container blog">
        <h1 className="title as-h1">Comming Soon</h1>
      </div>
    </Layout>
  )
}
